import React, {useEffect, useState} from 'react';
import {Button, TextField} from "@mui/material";
import {useCustomModal} from "../../pages/modals/custom-message-modal";
import {getDateFormats, getDefaultDateFormats} from "../../models/DateParser";

const MAX_FORMAT_LENGTH = 100;
const MAX_SAVED_FORMATS = 100;

const DateFormatModal = () => {
    const [customFormatString, setCustomFormatString] = useState('');
    const { hideModal } = useCustomModal();

    const handleCustomFormatChange = (e: any) => {
        setCustomFormatString(e.target.value);
    };

    useEffect(() => {
        const customFormats = localStorage.getItem('customDateFormats');
        if (customFormats && areValidDateFormats(customFormats)) {
            setCustomFormatString(customFormats);
        }
    }, []);

    const areValidDateFormats = (formatString: string): boolean => {
        const formats = formatString.split('\n');
        return formats.length < MAX_SAVED_FORMATS && formats.every(isValidDateFormat);
    }

    const saveAndClose = () => {
        if (!areValidDateFormats(customFormatString)) {
            alert('Invalid date format detected. Please correct your input.');
            return;
        }
        localStorage.setItem('customDateFormats', customFormatString);
        hideModal();
    }

    return (
        <div className={'transformSettingsModal'} style={{'padding': '5px'}}>
            <h1>View/Edit Date Formats</h1>
            <p>The system will recognize dates in any of the following formats. Note that they are listed with dashes as
            the separator, but dates with spaces, slashes, or dots will also be recognized.</p>
            <TextField
                label="Default Formats"
                multiline
                rows={10}
                value={getDefaultDateFormats().join('\n')}
                fullWidth
                margin="normal"
                InputProps={{
                    readOnly: true,
                    style: { fontFamily: 'monospace' }
                }}
            />
            <p>If needed, you can provide additional date formats here.
                See documentation <a href={"https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/"} target="_blank" rel="noopener noreferrer">here</a>.
                Be sure to use dashes as the separator.</p>
            <TextField
                label="Custom Formats"
                multiline
                rows={5}
                value={customFormatString}
                onChange={handleCustomFormatChange}
                fullWidth
                margin="normal"
                placeholder="Enter your custom date formats one per line..."
                InputProps={{
                    style: { fontFamily: 'monospace' }
                }}
            />

            <div className={'container'}>
                <Button variant="contained" color="secondary" className={'transform-button'}
                        onClick={() => saveAndClose()}>
                    OK
                </Button>
            </div>
        </div>
    );
};

// Basic validation for date format strings
export const isValidDateFormat = (format: string): boolean => {
    return (
        format.length <= MAX_FORMAT_LENGTH &&
        !format.includes('</') && // Prevent HTML injection attempts
        !format.includes('${') && // Prevent template literal injection
        !format.includes('javascript:') // Prevent javascript: URLs
    );
};

export default DateFormatModal;