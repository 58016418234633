import moment from "moment";
import {getPreferredDateFormat} from "../services/rules/defaultRules";
import {isValidDateFormat} from "../components/webviewer-multipanel/DateFormatModal";

//These first two lists are formats where it could be unclear which number represents a month and which represents a day.
const DAY_MONTH_DATE_FORMATS = [
    "DD-MM-YY",
    "DD-MM-YYYY",
    "D-M-YY",
    "D-M-YYYY" //Just one D will work for one or two-digit numbers. So this format works for 1/2/2024 and 11/12/2024. But not 01/02/2024
];

const MONTH_DAY_DATE_FORMATS = [
    "MM-DD-YY",
    "MM-DD-YYYY",
    "M-D-YY",
    "M-D-YYYY"
];

const OTHER_DATE_FORMATS = [
    //note - these should all have DATE_UNIT_SEPARATOR and no other
    //punctuation besides pattern details
    //MMM is for Jan, MMMM is for January. Each format does NOT accept the other one.
    "MMMM-D-YYYY",
    "D-MMMM-YYYY",
    "DD-MMM-YYYY",
    "DD-MMM-YY",
    "D-MMMM-YYYY",
    "D-MMM-YYYY",
    "D-MMM-YY",
    "DDMMMMYYYY",
    "DDMMMMYY",
    "DDMMMYY",
    "DDMMMYYYY",
    "DMMMYYYY",
    "DDMMM-YYYY",
    "DDMMM-YY",
    "DDMMMM-YY",
    "DDMMMM-YYYY",
    "YYYY-M-D",
    "YYYY-MM-DD",
    "YYYY-MMM",
    "DD-MMMYY",
    "DD-MMMYYYY",
    "MMMD-YYYY",
    "MMMDD-YYYY",
    //Do is for 1rst, 2nd, 3rd, 4th, etc.
    "Do-MMMM-YYYY",
    "Do-MMM-YYYY",
    "DoMMMYYYY",
    "MMMM-DD",
    "MMMM-Do",
    "MMMM-D",
    "MMMM-D-YYYY",
    "MMMM-D-YYYY",
    "D-MMMM-YYYY",
    "MMM-D-YYYY",
    "MMMM-Do-YYYY",
    "MMMM-D-YYYY",
    "MMMM-Do-YYYY",
    "MMMM-YYYY",
    "MMM-YYYY",
    "MMM-YY",
    "M-YYYY",
    "MM-YYYY",
    "MMMYYYY",
    "YYYY",
    "YYYY-YYYY", //For year ranges like 2023/2024
    "[FY]YYYY", //FY for fiscal year
    "[FY-]YYYY",
    "[FY-]YYYY/YYYY",
    "[FY']YY",
    "[Q]Q-YYYY", //Q for quarter
    "[W]w-YYYY", //W for week of year
    "[w]w-YYYY",
    "MM-DD"];

export const getDefaultDateFormats = () => {
    const preferredFormat = getPreferredDateFormat();
    if (preferredFormat === 'DAY/MONTH/YEAR') {
        return [
            ...DAY_MONTH_DATE_FORMATS,
            ...MONTH_DAY_DATE_FORMATS,
            ...OTHER_DATE_FORMATS,
        ];
    } else {
        return [
            ...MONTH_DAY_DATE_FORMATS,
            ...DAY_MONTH_DATE_FORMATS,
            ...OTHER_DATE_FORMATS,
        ];
    }
}

export const getDateFormats = () => {
    const customFormats = localStorage.getItem('customDateFormats')?.split('\n').filter(format => isValidDateFormat(format)) || [];
    return getDefaultDateFormats().concat(customFormats);
}

export function parseDate(dateStr: string): moment.Moment|undefined {
    const normalizedDateStr = normalizeDateStr(dateStr)
    let parsedDate: moment.Moment|undefined = undefined;

    for (const dateFormat of getDateFormats()) {
        parsedDate = moment(normalizedDateStr, dateFormat, true)
        if (parsedDate.isValid()) {
            break;
        } else {
            parsedDate = undefined;
        }
    }

    if (parsedDate === undefined || !parsedDate.isValid()) {
        console.log(`${dateStr} is not in one of the supported formats. We'll see if we can detect the format.`);
        //This isn't ideal because when it's parsed this way the format isn't stored. So we do it as a last resort.
        parsedDate = moment(dateStr);
        if (parsedDate === undefined || !parsedDate.isValid()) {
            console.log(`Failed to parse date string when transforming: ${dateStr}`);
            parsedDate = undefined;
        }
    }
    return parsedDate;
}

export const DATE_UNIT_SEPARATOR = '-';

//There are several symbols that get used in between numbers in dates, like slashes, dots, or spaces. One of the steps
//here is to replace all of those with dashes so that in the date formats above we can assume the strings have dashes.
function normalizeDateStr(dateStr: string): string {
    return dateStr
        .trim()
        .replace('of', '')
        .replace(/\s+|,|-|\.|\/|\\/g, DATE_UNIT_SEPARATOR)
        .replace(new RegExp(`${DATE_UNIT_SEPARATOR}+`, 'g'), DATE_UNIT_SEPARATOR);
}
