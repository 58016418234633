import React, {useEffect, useRef, useState} from 'react';
import {useCustomModal} from "./custom-message-modal";
import ModalIconContent from "./modal-content/modal-icon-content";

//This asks the user to choose a match category for the mark they just added.
const DockCorruptPagesConsentModal = (props : { onConsent: () => void,onCancel: ()=>void }) => {
    const { hideModal } = useCustomModal();
    const modalRef = useRef<HTMLDivElement>(null);
    const onSubmit = () => {
        props.onConsent()
        hideModal()
    }
    const onCancel = () => {
        props.onCancel()
        hideModal()
    }


    return <div ref={modalRef} style={{position: "relative",
        width: 550, display: "flex", alignItems: 'center',
        flexDirection: "column", justifyContent: "center", padding: "20px 30px"
    }}>
        {/*<ModalIconContent isDone/>*/}
        <h4 style={{fontWeight: "bold", fontSize: 20}}>Warning</h4>
        {/*<ModalIconContent/>*/}
        <p style={{marginBottom:"0px",textAlign:"center"}}>
            RLS Protect has detected the file you are opening may be corrupt. If you continue, the file will be corrected and reloaded.
            <br />
            Click OK to continue or CANCEL to exit.
        </p>

        <div style={{marginTop:"10px", float: "right"}}>
            <button onClick={onCancel} className={"sanitize-first-modal"} style={{margin: "0px 10px", backgroundColor: '#e60024'}}>Cancel
            </button>
            <button onClick={onSubmit} className={"corrupt-pages-button"} style={{margin:"0px 10px"}}>Ok</button>
        </div>

    </div>


};

export default DockCorruptPagesConsentModal;
