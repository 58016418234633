import React, {useEffect, useState} from "react";
import "./project/project.scss"
import Button from "@mui/material/Button/Button";
import {
    DataGridPro,
    GridColDef,
    GridRowId,
    GridRowsProp,
} from "@mui/x-data-grid-pro";
import {Box, MenuItem, Paper, Stack, Typography} from "@mui/material";
import {setSelectedFiles, setSelectedVersionsFiles} from "../pages/user/projects/single-project";
import {
    getEncodedFileName,
    getFileVersionUrl,
    listFileVersions,
    putApiFileById,
    updateFileTagByVersionId
} from "../services/files";
import {Select} from '@mui/material';
import {filesSelector} from "../redux/file-management";
import {useDispatch} from "react-redux";
import {updateFile} from "../redux/file-management";
import {useAppSelector} from "../hooks/redux-hook";
import {FileState} from "../models/FileState";
import {saveAs} from "file-saver";
import {showSnackbar} from "../redux/snackbar";
import DownloadIcon from "@mui/icons-material/Download";
import {Refresh, RestorePage} from "@mui/icons-material";
import {useCustomModal} from "../pages/modals/custom-message-modal";
import {RestoreFileVersionModal} from "../pages/modals/modal-content/restore-file-version-modal";
import {id} from "date-fns/locale";
import {GridInputSelectionModel} from "@mui/x-data-grid/models/gridSelectionModel";
import {verify} from "node:crypto";

export const fileCategories = [
    {label: "Unassigned", value: "Unassigned"},
    {label: "3rd Country Inspections (Reporting Summary)", value: "3rd Country Inspections (Reporting Summary)"},
    {label: "Agreement from another sponsor", value: "Agreement from another sponsor"},
    {label: "AMPD - Full", value: "AMPD - Full"},
    {label: "ASR-DSUR", value: "ASR-DSUR"},
    {
        label: "Attachment of justification of low interventional clinical trial",
        value: "Attachment of justification of low interventional clinical trial"
    },
    {label: "Authorisation of manufacturing and import", value: "Authorisation of manufacturing and import"},
    {label: "Clinical Overview", value: "Clinical Overview"},
    {
        label: "Compliance with national requirements on Data Protection",
        value: "Compliance with national requirements on Data Protection"
    },
    {label: "Compliance with Regulation (EU) 2016/679", value: "Compliance with Regulation (EU) 2016/679"},
    {label: "Compliance with use of Biological samples", value: "Compliance with use of Biological samples"},
    {label: "Content labelling of the IMPs", value: "Content labelling of the IMPs"},
    {label: "Cover letter", value: "Cover letter"},
    {label: "CSR Synopsis", value: "CSR Synopsis "},
    {label: "Data Safety Monitoring Committee Charter", value: "Data Safety Monitoring Committee Charter"},
    {
        label: "End of Trial, Non-safety Temporary Halt & Early Termination",
        value: "End of Trial, Non-safety Temporary Halt & Early Termination"
    },
    {label: "Financial arrangements", value: "Financial arrangements"},
    {label: "Full CSR ", value: "Full CSR"},
    {label: "IMPD-Q", value: "IMPD-Q"},
    {label: "Informed Consent", value: "Informed Consent"},
    {label: "IMPD-Q Placebo", value: "IMPD-Q Placebo"},
    {label: "IMPD-Safety and Efficacy", value: "IMPD-Safety and Efficacy"},
    {label: "Investigator Brochure", value: "Investigator Brochure"},
    {label: "Investigator CV", value: "Investigator CV"},
    {label: "Member State Communication", value: "Member State Communication"},
    {label: "Member State Conclusions", value: "Member State Conclusions"},
    {label: "Member State Inspections", value: "Member State Inspections"},
    {label: "PIP opinion", value: "PIP opinion"},
    {label: "Plain Language Summary", value: "Plain Language Summary "},
    {label: "Proof of insurance", value: "Proof of insurance"},
    {label: "Proof of payment", value: "Proof of payment"},
    {label: "Protocol", value: "Protocol"},
    {label: "QP GMP certification", value: "QP GMP certification"},
    {label: "Recruitment arrangements", value: "Recruitment arrangements"},
    {label: "Responses from sponsor", value: "Responses from sponsor"},
    {label: "Safety Temporary Halt or Early Termination ", value: "Safety Temporary Halt or Early Termination "},
    {label: "Scientific advice - Quality", value: "Scientific advice - Quality"},
    {label: "Serious breaches", value: "Serious breaches"},
    {label: "Simplified IMPD-Q", value: "Simplified IMPD-Q"},
    {label: "Simplified IMPD-Safety and Efficacy", value: "Simplified IMPD-Safety and Efficacy"},
    {label: "Study design", value: "Study design"},
    {label: "Subject information and informed consent form", value: "Subject information and informed consent form"},
    {label: "Substantial Modifications ", value: "Substantial Modifications "},
    {label: "Suitability of the facilities", value: "Suitability of the facilities"},
    {label: "Suitability of the investigator", value: "Suitability of the investigator"},
    {label: "Summary of Interim Analysis", value: "Summary of Interim Analysis "},
    {label: "Summary of Product Characteristics (SmPC)", value: "Summary of Product Characteristics (SmPC)"},
    {label: "Summary of scientific advice", value: "Summary of scientific advice"},
    {label: "SUSARs", value: "SUSARs"},
    {label: "Synopsis of the Protocol", value: "Synopsis of the Protocol"},
    {label: "Unexpected events (Art 53)", value: "Unexpected events (Art 53)"},
    {label: "Union Controls", value: "Union Controls"},
    {label: "Urgent Safety measures", value: "Urgent Safety measures"},
    {label: "Other", value: "Other"},
    {label: "RLS Protect Risk", value: "RLS Protect Risk"}
];




function DetailPanelContent({row: rowProp,p,data}: { row: any,p: any,data: any }) {
    const dispatch = useDispatch();
    const { showModal } = useCustomModal();
    let [fileVersions, setFileVersions] = useState<any[]>([]);
    // . : + = @ _ / -
    const tagPattern = /^[a-zA-Z0-9.:=@_\/\-\s]+$/

    let fileVersionName = "";

    async function getVersionRows(row: any) {
        localStorage.removeItem("versionUrls")
        localStorage.removeItem("versionFileNames")
        const versions = await listFileVersions(p.projectId, fileVersionName);
        let rows: GridRowsProp = [];
        let i = versions.length;
        let j = 0;
        versions.forEach((version) => {
            if (!version.IsLatest) {
                rows = rows.concat({
                    id: j,
                    col1: i,
                    col2: getReadableFileSizeString(version.Size),
                    col6: new Date(version.LastModified),
                    col7: version.ETag,
                    col10: decodeURI(row.col1),
                    col11: version.VersionId,
                    col12: row.col7
                });
            }
            else {
                rows = rows.concat({
                    id: j,
                    col1: "CURRENT",
                    col2: getReadableFileSizeString(version.Size),
                    col6: new Date(version.LastModified),
                    col7: version.ETag,
                    col10: decodeURI(row.col1),
                    col11: version.VersionId,
                    col12: row.col7
                });
            }
            j++;
            i--;
        });
        // @ts-ignore
        setFileVersions(rows);
    };


    async function downloadFileVersion(projectId: string | undefined, fileName: string, versionId: string) {
        try {
            const url = await getFileVersionUrl(projectId, decodeURIComponent(fileName), versionId);
            const response = await fetch(url.url);
            const blob = await response.blob();
            saveAs(blob, decodeURIComponent(fileName));
            dispatch(showSnackbar({message: "Download Successful!", type: "info"}));
            console.log('All files downloaded successfully.');
        } catch (error) {
            console.error('An error occurred while downloading files:', error);
            dispatch(showSnackbar({message: "Error Downloading Files!", type: "error"}));
        }
    }

    const versionColumns: GridColDef[] = [
        {
            field: 'col1', headerName: 'Version Number', flex: 2
        },
        {
            field: 'col2', headerName: 'File Size', flex: 2
        },
        {
            field: 'col6', headerName: 'Last Modified', flex: 8
        },
        {
            field: 'col7', headerName: 'Tags', flex: 15, editable: true
        },
        {
            field: 'col8', headerName: 'Download', flex: 2, minWidth: 100, maxWidth: 100, renderCell: params => {
                if (params.row.col1!=="CURRENT") {
                    return <DownloadIcon onClick={() => {
                        downloadFileVersion(p.projectId, params.row.col10, params.row.col11)
                    }} style={{alignSelf: "center", textAlign: 'center'}} fontSize="small"/>
                }
            }
        },
        {
            field: 'col9', headerName: 'Restore', flex: 2, minWidth: 100, maxWidth: 100, renderCell: params => {
                if (params.row.col1!=="CURRENT") {
                    return <RestorePage onClick={async () => {
                        showModal(RestoreFileVersionModal, {projectId: p.projectId, fileName: params.row.col10, versionId: params.row.col11, fileId: params.row.col12, lastModifiedDate: params.row.col6.toString(), tag: params.row.col7})
                    }} style={{alignSelf: "center", textAlign: 'center'}} fontSize="small"/>
                }
            }
        },
        {
            field: 'col10', headerName: '', maxWidth: 0,
        },
        {
            field: 'col11', headerName: '', maxWidth: 0,
        },
    ];
    fileVersionName = decodeURIComponent(rowProp.col1);
    if(fileVersions.length < 1) {
        getVersionRows(rowProp).then();
    }
    async function updateVersionTag(projectId: string, fileName: string, versionId: string, newTag: string) {
        await updateFileTagByVersionId(projectId, fileName, versionId, newTag);
        dispatch(showSnackbar({message: "Updated file version tag!", type: "info"}));
    }

    // @ts-ignore
    return (
        <Stack sx={{py: 2, height: 1, boxSizing: 'border-box'}} direction="column">
            <Paper sx={{flex: 1, mx: 'auto', width: '90%', p: 1}}>
                <Stack direction="column" spacing={1} sx={{height: 1}}>
                    <Typography variant="h6">{rowProp.col1 + ` - Versions`}</Typography>
                    <DataGridPro
                        columnVisibilityModel={{
                            // Hide columns filename, version id, and inherited row file id, used in the requests
                            col10: false,
                            col11: false,
                            col12: false
                        }}
                        {...data}
                        initialState={{
                            // @ts-ignore
                            ...data.initialState,
                            pagination: {paginationModel: {pageSize: 5}},
                        }}
                        density="compact"
                        autoHeight
                        columns={versionColumns}
                        rows={fileVersions}
                        checkboxSelection
                        onRowSelectionModelChange={async (ids) => {
                            const selectedIDs = new Set(ids);
                            const selectedRows = fileVersions.filter((row) =>
                                selectedIDs.has(row.id),
                            );
                            localStorage.setItem("versionFileNames", JSON.stringify(selectedRows.map((row) => `v${row.col1} * ${new Date(row.col6).toLocaleDateString()} * ${row.col10}`)));
                            const promises: any = []
                            for (const row of selectedRows) {
                                promises.push(await getFileVersionUrl(p.projectId, decodeURIComponent(row.col10), row.col11))
                            }
                            const response = await Promise.all(promises);
                            localStorage.setItem("versionUrls", JSON.stringify(response.map((row) => row.url)));
                            p.seIsDisabledCompareButton(selectedIDs.size < 2);
                        }}
                        processRowUpdate={async (updatedRow, originalRow) => {
                            if(updatedRow.col7 !== originalRow.col7) {
                                const error = updatedRow.col7.match(tagPattern) === null;
                                if(error) {
                                    dispatch(showSnackbar({message: "Only alphanumeric and . : + = @ _ / - allowed!", type: "error"}))
                                }
                                else {
                                    // @ts-ignore
                                    await updateVersionTag(p.projectId, updatedRow.col10, updatedRow.col11, updatedRow.col7);
                                    originalRow.col7 = updatedRow.col7;
                                }
                            }
                        }}
                        disableRowSelectionOnClick={true}
                        pagination
                        sx={{flex: 1}}
                    />
                </Stack>
            </Paper>
        </Stack>
    );
}





export function FilesComponent(p: {
projectId: string | undefined,
    files: any[],
    selectedRows: any,
    setNoFilesSelected: Function,
    seIsDisabledCompareButton: Function,
    onDoubleClickRow: Function
}) {
    const projectFiles: FileState[] = useAppSelector(filesSelector);
    const dispatch = useDispatch();
    const data = getRows();

    function getRows() {
        let rows: GridRowsProp = [];
        if (p.files.length === 0) {
            return rows;
        }
        let i = 0;
        for (let file of p.files) {
            const projectFile = projectFiles.find((projectFile: FileState) => projectFile.name === file.name);
            if (projectFile) {
                const userName = projectFile.createdByName || "";
                rows = rows.concat({
                    id: i,
                    col1: file.name,
                    col2: getReadableFileSizeString(file.size),
                    col3: '-',
                    col4: userName,
                    col5: new Date(file.createdAt),
                    col6: new Date(file.lastModifiedAt),
                    col7: projectFile.id
                });
            }
            i++;
        }

        return rows;
    }



    function handleChange(event: any, fileNameOfCategoryChange: string) {
        if (fileNameOfCategoryChange && p.projectId) {
            const file = projectFiles.find((file: any) => file.name === fileNameOfCategoryChange);
            if (file) {
                const id = file.id;
                putApiFileById(JSON.stringify(id),
                    {
                        id: id,
                        name: file.name,
                        size: file.size,
                        category: event.target.value as string,
                        projectId: parseInt(p.projectId),
                        isDocOpen: file.isDocOpen ? file.isDocOpen : false,
                        createdByName: file.createdByName
                    });

                dispatch(updateFile({
                    ...file,
                    id: id,
                    name: fileNameOfCategoryChange,
                    category: event.target.value as string,
                    projectId: parseInt(p.projectId)
                }))
            }
        }
    }


    const [
        detailPanelExpandedRowIds,
        setDetailPanelExpandedRowIds
    ] = React.useState<GridRowId[]>([]);

    const handleDetailPanelExpandedRowIdsChange = React.useCallback(
        async (newIds: GridRowId[]) => {
            //setFileVersions([]);
            if (newIds.length > 1) {
                setDetailPanelExpandedRowIds([newIds[newIds.length - 1]]);
            } else {
                setDetailPanelExpandedRowIds(newIds);
            }
        },
        []
    );

    const columns: GridColDef[] = [
        {
            field: 'col1', headerName: 'File Name', flex: 15
        },
        {
            field: 'col2', headerName: 'File Size', flex: 2
        },
        {
            field: 'col4', headerName: 'Owner', flex: 4
        },
        {
            field: 'col5', headerName: 'Created At', flex: 5
        },
        {
            field: 'col6', headerName: 'Last Modified', flex: 5
        },
        {
            field: 'col9', headerName: 'File Category', flex: 5, renderCell: params => {
                const currentCategory = projectFiles.find((file: FileState) => file.name === params.row.col1)?.category;
                return <Select onChange={(event) => handleChange(event, params.row.col1)}
                               value={currentCategory || "Unassigned"}
                               style={{minWidth: 160, width: "100%"}}>
                    {fileCategories.map((category, index) => {
                        return <MenuItem key={index} value={category.value}> {category.value} </MenuItem>
                    })}
                </Select>
            }

        },
        {
            field: 'col8', headerName: 'File Logs', flex: 3, renderCell: params => {
                return <Button onClick={() => {
                    window.open('/app/user/docs/' + getEncodedFileName(filterPDFExtension(params.row.col1)) + '/' + p.projectId + '/logsFile');
                }}
                               color="secondary" variant="outlined">
                    Go to Logs
                </Button>
            }
        },
    ];

        return <Box style={{minHeight: 350, height: 500, maxHeight: 600, width: '100%'}}>
            <DataGridPro
                columnVisibilityModel={{
                    // Hide columns status and traderName, the other columns will remain visible
                    col7: false
                }}
                sx={{
                    // disable cell selection style
                    '.MuiDataGrid-cell:focus': {
                        outline: 'none'
                    },
                    // pointer cursor on ALL rows
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer'
                    }
                }}
                initialState={{
                    pagination: {paginationModel: {pageSize: 25}},
                }}
                rowHeight={30}
                style={{height: "calc(100vh - 404px)"}}
                rows={getRows()}
                columns={columns}
                pagination
                pageSizeOptions={[25, 50, 100]}
                checkboxSelection
                onRowDoubleClick={({id, row}) => {
                    setSelectedFiles([row]);
                    p.onDoubleClickRow()
                }}
                onRowSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRows = data.filter((row) => {
                            return selectedIDs.has(row.id)
                        }
                    );


                    setSelectedFiles(selectedRows);
                    p.setNoFilesSelected(selectedRows.length===0);
                    p.seIsDisabledCompareButton(selectedRows.length < 2);
                }}

                getDetailPanelHeight={() => 'auto'} // Height based on the content.
                getDetailPanelContent={({row}) => (
                    <Box sx={{p: 2}}>{<DetailPanelContent row={row} p={p} data={data} />}</Box>
                )}
                detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                onDetailPanelExpandedRowIdsChange={
                    handleDetailPanelExpandedRowIdsChange
                }
                disableRowSelectionOnClick={true}
                {...data}
            />
        </Box>
}

function getReadableFileSizeString(fileSizeInBytes: number) {
    var i = -1;
    var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes /= 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

const pdfExtenstion = '.pdf'
export function filterPDFExtension(name: string): string {
    return name.endsWith(pdfExtenstion) ? name.substring(0, name.length-4) : name
}
