import React, {useEffect, useRef, useState} from 'react';
import {useCustomModal} from "./custom-message-modal";
import ModalIconContent from "./modal-content/modal-icon-content";
import {DataGrid, GridColDef, GridRowsProp} from "@mui/x-data-grid";
import {Box, Grid} from "@mui/material";


//This asks the user to choose a match category for the mark they just added.
const BatchDeleteModal = (props : {fileNamesWithTabIndexes: {fileName: string, index: number}[], onRemoveSelected: any}) => {
    const { hideModal } = useCustomModal();
    const modalRef = useRef<HTMLDivElement>(null);
    const [selectedFilesTabIndexes, setSelectedFileTabIndexes] = useState<number[]>(props.fileNamesWithTabIndexes.map((fi) => fi.index))
    const [selectAll, setSelectAll] = useState(true)
    const [marksSelected, setMarksSelected] = useState(true)
    const [highlightsSelected, setHighlightsSelected] = useState(true)
    const [commentsSelected, setCommentsSelected] = useState(true)

    useEffect(()=> {
        if (marksSelected && highlightsSelected && commentsSelected) {
            setSelectAll(true)
        } else {
            setSelectAll(false)
        }
    }, [marksSelected, highlightsSelected, commentsSelected])

    const handleSelectAll = function (checkedValue: boolean) {
        setSelectAll(checkedValue)
        setMarksSelected(checkedValue)
        setHighlightsSelected(checkedValue)
        setCommentsSelected(checkedValue)
    }

    const handleOkClick = function () {
        props.onRemoveSelected(marksSelected, highlightsSelected, commentsSelected, selectedFilesTabIndexes)
        hideModal()
    }

    const handleCancelClick = function () {
        hideModal()
    }

    function getRows() {
        let rows: GridRowsProp = [];
        props.fileNamesWithTabIndexes.forEach((fileNamesWithTabIndex) => {
            rows = rows.concat({
                id: fileNamesWithTabIndex.index,
                col1: fileNamesWithTabIndex.fileName,
            });
        })
        return rows;
    }

    let columns: GridColDef[]
    columns = [{
        field: 'col1', headerName: 'File Name', flex: 15, cellClassName: 'cell-left-border', headerClassName: 'cell-left-border'
    }]

    return <div ref={modalRef} style={{position: "relative",
        width: 500, display: "flex", padding: "0px 30px 20px", flexDirection: "column",
    }}>
        <div style={{ display: "flex", alignItems: 'center', flexDirection: "column", justifyContent: "center"}}>
            <h4 style={{fontWeight:"bold"}}>Batch delete marks</h4>
        </div>
            <p>Select annotations types to delete</p>
        <div>
            <DataGrid
                rowHeight={30}
                style={{ height: "calc(100vh - 804px)", minHeight: 270, marginBottom: 0, width: 500 }}
                rows={getRows()}
                columns={columns}
                pagination
                pageSize={5}
                checkboxSelection
                selectionModel={selectedFilesTabIndexes}
                onSelectionModelChange={(ids: any) => {
                    // @ts-ignore
                    setSelectedFileTabIndexes(ids)
                }}
            />
        </div>
        <div style={{display: "flex", flexDirection: "row"}}>
            <p >Annotation Types:</p>
            <div className='button-box'>
                <div className='type-div' style={{backgroundColor: "#ededed"}}>
                    <input className='table-checkbox' type={"checkbox"} checked={selectAll} onChange={(event) => handleSelectAll(event.target.checked)}/> <p style={{marginTop: 3}}>Select All</p>
                </div>
                <div className='type-div'>
                    <input className='table-checkbox' type={"checkbox"} checked={marksSelected} onChange={() => setMarksSelected(!marksSelected)}/> <p style={{marginTop: 3}}>Redaction Marks</p>
                </div>
                <div className='type-div' style={{backgroundColor: "#ededed"}}>
                    <input className='table-checkbox'  type={"checkbox"} checked={highlightsSelected} onChange={() => setHighlightsSelected(!highlightsSelected)}/> <p style={{marginTop: 3}}>Replaced Highlights</p>
                </div>
                <div className='type-div'>
                    <input className='table-checkbox' type={"checkbox"} checked={commentsSelected} onChange={() => setCommentsSelected(!commentsSelected)}/> <p style={{marginTop: 3}}>Comments</p>
                </div>
            </div>
        </div>
        <div className="button-container" style={{display: "flex", justifyContent: "right"}}>
            <button className="rounded-button cancel-button" onClick={handleCancelClick}
                    disabled={false}>Cancel
            </button>
            <button className="rounded-button ok-button" onClick={handleOkClick}
                    disabled={(!marksSelected && !highlightsSelected && !commentsSelected ) || selectedFilesTabIndexes.length===0}>Remove Annotations
            </button>
        </div>
        {((!marksSelected && !highlightsSelected && !commentsSelected ) || selectedFilesTabIndexes.length===0) && <p style={{color: "#FF0000", marginLeft: 10}}>
            please select at least one file and one annotation type to delete
        </p>}
    </div>

};

export default BatchDeleteModal;
