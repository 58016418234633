import React, {useRef, useEffect, useState} from 'react';
import WebViewer from '@pdftron/webviewer';
import {Buttons, CompareModalBody, CustomModal} from "./utils/webviewer-modal-button-utils";
import {comparePanelSvg, saveSVGIcon} from "./utils/svg-files";
import WebViewerCompareView from "./utils/webviewer-compare-view";
import {createLogFileByProjectId, getApiFilesByProjectId, getFileTagByProjectIdAndFileName} from "../services/files";
import {getFileNameFromURL} from "../pages/user/projects/single-project";
import {Button} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../hooks/redux-hook";
import {useNavigate} from "react-router-dom";
import {UpdateFileTagModal} from "../pages/modals/modal-content/update-file-tag-modal";
import {useCustomModal} from "../pages/modals/custom-message-modal";
import {showSnackbar} from "../redux/snackbar";
import {startUploadingToS3} from "./file-management";
import {filterPDFExtension} from "./files-component";
import {useAuthService} from "../contexts/auth-context";
import {uploadLogsToS3} from "./webviewer";

let retryingCount = 1;
type log = {
    date: string,
    time: string,
    document: string,
    user?: string,
    roles?: string[],
    action: string,
    annotationType: string,
    pages: number[]
}
const CompareViewer = (p: { files: any[], patternSetID?: number, projectID?: number, taskId?: number,shouldCompareVersion?:boolean, initialTaskStatus?: string, isRestoring? : boolean, tag?: string}) => {
    const { showModal, hideModal } = useCustomModal();
    const dispatch = useAppDispatch();
    const auth = useAuthService();
    const viewer = useRef(null);
    const [showingProgressSpinner, setShowingProgressSpinner] = useState(false);
    const [continueTracking, setContinueTracking] = useState<boolean>(false)
    const webviewerCompareViewRef =useRef(new WebViewerCompareView());
    const { loaded, projects } = useAppSelector(state => state.projects)
    const activeProject = projects.find((val) => val.id === p.projectID);
    const nav = useNavigate();
    let fileNameAndIdMap = new Map<string, string>();
    const loadFiles = async ()=> {
        const files = await getApiFilesByProjectId(p.projectID!)
        let selectedFiles = []
        if(p.shouldCompareVersion){
            selectedFiles = JSON.parse(localStorage.getItem("versionUrls")!)
        }else {
            for (let i = 0; i < p.files.length; i++) {
                const index = files.findIndex((file: any) => getFileNameFromURL(file.url) === getFileNameFromURL(p.files[i]));
                if (index > -1) {
                    // @ts-ignore
                    fileNameAndIdMap.set(getFileNameFromURL(files[index].url) as string, files[index].id)
                    if (p.isRestoring) {
                        selectedFiles.push(p.files[i]);
                    } else {
                        selectedFiles.push(files[index].url) //use the URL from the most recent API call, because these AWS URLs expire after 15 minutes.
                    }
                }
            }
        }

        return selectedFiles;
    }

    const showProgressSpinner = () => {
        setShowingProgressSpinner(true);
        window.dispatchEvent(new Event('processStarted'))
    }

    // if using a class, equivalent of componentDidMount
    useEffect(() => {
        const openedFiles = async () => {
            return await loadFiles()
        }
        openedFiles().then(selectedFiles=> {
            webviewerCompareViewRef?.current?.setFiles(selectedFiles)
            WebViewer.WebComponent(
                {
                    licenseKey: process.env.REACT_APP_PDFTRON_LICENSE,
                    path: '/webviewer/lib',
                    fullAPI: true,
                    isAdminUser: true,
                    enableRedaction: false,
                    disabledElements: [
                        'redactionPanel',
                        'fullScreenButton',
                        'toolbarGroup-Shapes',
                        'toolbarGroup-Insert',
                        'toolbarGroup-Forms',
                        'toolbarGroup-FillAndSign',
                        'toolbarGroup-Annotate',
                        'toolbarGroup-Edit',
                    ]
                },
                viewer.current!,
            ).then((instance) => {
                const {UI,Core}=instance
                const {Tools}=Core
                webviewerCompareViewRef?.current?.setInstance(instance);
                const domDoc = document.getElementsByTagName('apryse-webviewer')[0].shadowRoot
                let logs: log[] = []

                const getDecodedFileName = () : string => {
                    const name = Core.documentViewer?.getDocument()?.getFilename().split('?')[0];
                    return decodeURIComponent(localStorage.getItem("versionUrls") ? name?.split("*")[2].trim() : name)
                }
                const hideProgressSpinner = () => {
                    setShowingProgressSpinner(false);
                    window.dispatchEvent(new Event('processEnded'));

                }
                const stopSaving = () => {
                    hideProgressSpinner();
                    window.dispatchEvent(new Event('processEnded'))
                }

                const saveFile = async (fileName: string, existingFileSave: boolean, temporaryLocalChangesSave: boolean, updatedFile?: any, saveTag?: string): Promise<string | undefined> => {
                    showProgressSpinner();
                    const fileId = fileNameAndIdMap.get(fileName);
                    //turn off the inactivity timer
                    window.dispatchEvent(new Event('processStarted'))

                    const doc = Core.documentViewer.getDocument();
                    await doc.getDocumentCompletePromise();
                    const xfdfString = await Core.annotationManager.exportAnnotations({fields: true});
                    let data;
                    try {
                        data = await doc.getFileData({ includeAnnotations: true, xfdfString: xfdfString,flags: Core.PDFNet.SDFDoc.SaveOptions.e_linearized});
                    } catch (e) {

                        if(retryingCount === 1){
                            await saveFile(fileName, existingFileSave, temporaryLocalChangesSave, updatedFile, saveTag)
                        }else{
                            console.log(e);
                            dispatch(showSnackbar({message: "Error getting file data", type: "error"}));
                            stopSaving();
                        }
                        retryingCount++;
                        return;
                    }
                    //This string contains all the information about annotations in the document.
                    const arr = new Uint8Array(data);
                    const blob = new Blob([arr]);

                    const file = new File([blob], fileName, {type: 'application/pdf'})
                    //We can be sure project ID is not null because we disable the save buttons when accessing the webviewer not from a project

                    console.log('uploading file to s3');
                    if (temporaryLocalChangesSave) {
                        return startUploadingToS3(updatedFile ? updatedFile : file, new AbortController().signal, p.projectID!, auth.loginInfo?.tenant?.user?.id, true, true, fileId as string, saveTag, temporaryLocalChangesSave ).then(()=>{
                            return "success";
                        }).catch(()=>{stopSaving(); return undefined})
                    }

                    return startUploadingToS3(updatedFile ? updatedFile : file, new AbortController().signal, p.projectID!, auth.loginInfo?.tenant?.user?.id, true, true, fileId as string, saveTag, false, ).then(()=>{
                        console.log('got a return value from s3');
                        const currentDocumentLogs = logs.filter(log => log.document === fileName)
                        //@ts-ignore
                        uploadLogsToS3(currentDocumentLogs, filterPDFExtension(fileName), p.projectID.toString())
                        logs = []
//                  logs = logs.filter(log => log.document !== fileName)
                        if(p.isRestoring) {
                            dispatch(showSnackbar({message: `Successfully restored ${fileName}`, type: "info"}));
                        }
                        else {
                            dispatch(showSnackbar({message: `Successfully saved ${fileName}`, type: "info"}));
                        }

                        stopSaving();
                        hideModal()
                        return undefined;
                    }).catch((e)=>{
                        console.log("=====",e)
                        dispatch(showSnackbar({message: "Error Saving File!", type: "error"}));
                        stopSaving();
                        return undefined;
                    })

                }

                const saveFileInPlace = async (showModalFlag : boolean) => {
                    const filename =  getDecodedFileName()
                    if(showModalFlag) {
                        const tag = await getFileTagByProjectIdAndFileName(p.projectID, filename)
                        let updatedTag = tag.tag;
                        await showModal(UpdateFileTagModal, {
                            projectId: p.projectID,
                            fileName: filename,
                            fileId: fileNameAndIdMap.get(filename),
                            tag: updatedTag,
                            updateTag: async (tag) => {
                                try {
                                    const updatedData = undefined
                                    updatedTag = tag;
                                    retryingCount = 1;
                                    await saveFile(filename, true, false, updatedData, updatedTag)
                                } catch {

                                }
                            }
                        })
                    }
                    else {
                        retryingCount = 1;
                        await saveFile(getDecodedFileName(), true, false,undefined, p.tag)
                    }
                }

                const handleSaveCompare=()=>{
                    //instance.UI.closeElements(['MainMenuFlyout']);
                    saveFileInPlace(true);
                }
                if (p.projectID) {
                    const defaultHeader = UI.Flyouts.getFlyout("MainMenuFlyout")
                    const saveButton = new Buttons("Save", "customSaveButton", handleSaveCompare, saveSVGIcon).setTitle("Save")
                    // @ts-ignore
                    const items = defaultHeader.properties.items;
                    const position = 4;
                    items.splice(position, 1, saveButton);
                    defaultHeader.setItems([...items]);
                }

                //compare custom modal and button
                const compareProcessingModal = new CustomModal("process-modal", true)
                    .addHeader({title: 'Compare Status', className: "text-center"})
                    .addBody(CompareModalBody.displayFiles(), {}).addFooter(instance)
                // @ts-ignore
                instance.UI.addCustomModal(compareProcessingModal)
                const comparePanelToggleButton = new Buttons("", "compare-toggle-button", () => {
                    if (instance.UI.isElementOpen('comparePanel')) {
                        instance.UI.closeElements(['comparePanel'])
                        comparePanelToggleButton.makeInActive(domDoc)
                    } else {
                        instance.UI.openElements(['comparePanel'])
                        comparePanelToggleButton.makeActive(domDoc)
                    }
                }, comparePanelSvg)
                const processFilesCompare = () => {
                    instance.UI.openElements(['process-modal'])
                    instance.UI.addEventListener(instance.UI.Events.MULTI_VIEWER_READY, () => WebViewerCompareView.processFiles(comparePanelToggleButton));
                    instance.UI.enterMultiViewerMode()
                }
                processFilesCompare();
            });
        })
    }, []);

    return (
        <div className="body-container" style={{flexDirection: "column", width: '100%'}}>
            <div style={{display: 'flex', margin: '0 0 0 0', gap: '2.3rem', height: '32px'}}>
                <Button style={{textDecoration: 'underline', color: "blue"}}
                        onClick={() => nav(`/app/user/workflow/projects/${activeProject?.id}`)}>Back
                    to {activeProject?.name}</Button>
            </div>
            <div className="DocViewer">
                <div className="webviewer" ref={viewer} style={{ height: "calc(100vh - 154px)", width: '70%', display: 'inline-block' }}></div>
            </div>
            {showingProgressSpinner  && <div className="progress-modal"><div className="spinner spinner-position-center"></div></div>}
        </div>
    );
};

export default CompareViewer;
