import React, { useEffect, useState } from 'react';
import { useAuthService } from '../../../../contexts/auth-context';

import "../../../pages.scss";
import WebViewerComponent from '../../../../components/webviewer';
import {useLocation, useSearchParams} from "react-router-dom";
import {Project} from "../../../../models/Project";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux-hook";
import {projectSelector} from "../../../../redux/projects";
import CompareViewer from "../../../../components/compare-view";

interface stateProps {
  files?: string[],
  taskid?: number,
  initialTaskStatus?: string,
  isRestoring?: boolean,
  tag?: string;
  shouldCompareVersion?: boolean
}
const ProjectDocViewer = (props: stateProps | undefined) => {
  const [searchParams] = useSearchParams();
  const isCompare = searchParams.get('compare');
  const location = useLocation();
  const [filesToShow, setFileToShow] = useState<string[] | undefined>();
  const [taskId, setTaskId] = useState<number>(0);
  const [isRestoring, setRestoring] = useState<boolean>(false);
  const [tag, setTag] = useState<string>("");
  const [shouldCompareVersion, setShouldCompareVersion] = useState<boolean>(false);
  const [taskStatus, setTaskStatus] = useState<string>('NOT_STARTED');
  let urlArray = window.location.href.split('/');
  urlArray.pop()
  const projectID = urlArray.pop()!
  const project: Project | undefined = useAppSelector((state) => projectSelector(state, +projectID));
  const patternSetID = project && project.patternSetID? project.patternSetID : undefined

  useEffect(() => {
    const { files, taskid, initialTaskStatus, isRestoring, tag, shouldCompareVersion } = location.state as stateProps;
    if(files) { setFileToShow(files); }
    if(taskid) {setTaskId(taskid)}
    if(tag) {setTag((tag))}
    if(isRestoring) {setRestoring(true)}
    if(initialTaskStatus) {setTaskStatus(initialTaskStatus)}
    if(shouldCompareVersion) {setShouldCompareVersion(shouldCompareVersion)}
  }, [location.state]);

  //Only render if we have files to show and the project is loaded
  return (filesToShow && project && project.id !== -1) ? (isCompare ? <CompareViewer shouldCompareVersion={shouldCompareVersion} taskId={+taskId+1} initialTaskStatus={taskStatus} projectID={+projectID} files={filesToShow} patternSetID={patternSetID} isRestoring={isRestoring} tag={tag}  /> : <WebViewerComponent taskId={+taskId+1} initialTaskStatus={taskStatus} projectID={+projectID} files={filesToShow} patternSetID={patternSetID} isRestoring={isRestoring} tag={tag} />) : null;
};

export default ProjectDocViewer;
